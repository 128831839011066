

import './App.css';
import { useEffect, useState, useRef } from 'react';
import React from 'react';
import arrow from './assets/send1.svg';
import sparkles from './assets/stars.svg';
import bg from './assets/bg.jpg';
import { Dna } from 'react-loader-spinner';
import Select from 'react-select';


function App() {
 const [userInput, setUserInput] = useState('');
 const [loading, setLoading] = useState(false);
 const [messages, setMessages] = useState([
   {
     msg: "👋 Hello! I'm an AI chatbot capable of addressing your inquiries. Feel free to ask any questions you may have!",
     category: null,
     fromUser: false,
   }
 ]);


 const bottomRef = useRef(null);
 const textareaRef = useRef(null);


 const themes = {
   primaryColor: "#4900ff",
   blackColor: "#000000",
   secondryColor: "#475569",
   primaryFontColor: "white",
   secondryFontColor: "#2C3333",
   mainBgColor: "#ffffff",
   logoColor: "#ffffff",
   backgroudImage: bg
 };

 const [banner, setBanner] = useState('');
 const [botName, setBotName] = useState('');
 const [categories, setCategories] = useState([{ value: '', label: "Select Category" }]);
 const [selectedCategory, setSelectedCategory] = useState("");


 const handleTypeSelect = e => {
   setSelectedCategory(e.value);
 };


 let loaded = false;


 useEffect(() => {
   const urlParams = new URLSearchParams(window.location.search);
   let websiteRef = urlParams.get("websiteRef");
   if (websiteRef != null && !loaded) {
     loaded = true;


     fetch(`${process.env.REACT_APP_SERVER_URL}/api/website/site=${websiteRef}`)
       .then((response) => response.json())
       .then((data) => {
         if (data.banner_url === "") {
           setBanner(bg);
         } else {
           setBanner(data.banner_url);
         }


         setBotName(data.bot_name);
         if (data.categories && data.categories.length > 0 && data.categories.includes(',')) {
           let categoryList = data.categories.split(',');
           let categoryUuidList = data.category_uuid.split(',');
           categoryList.map((obj, index) => (
             setCategories(oldData => [...oldData, { value: categoryUuidList[index], label: obj }])
           ))
         } else if (data.categories && data.categories.length > 0) {
           setCategories(oldData => [...oldData, { value: data.category_uuid, label: data.categories }])
         }
       })
       .catch((err) => {
         console.log(err.message);
       });
   } else if (!loaded) {
     setBanner(themes.backgroudImage);
     setBotName("RevizeBot");
   }
 }, []);


 const decoder = new TextDecoder("utf-8");
 let test = "";


 function urlify(text) {
   var pattern = /(https?:\/\/[^\s]+)/g;


   return text.replace(pattern, function (url) {
     console.log("URL", url);
     return '<a href="' + url + '" target="_blank"><u style="color: blue;">' + url + '</u></a>';
   });
 }

function createMarkup() {
  return {__html: ''};
}

 useEffect(() => {
   if (userInput !== '' && messages[messages.length - 2].fromUser === false) {
     setLoading(true);
     const urlParams = new URLSearchParams(window.location.search);
     let websiteRef = urlParams.get("websiteRef");


     const fetchData = async () => {
       try {
         const response = await Promise.race([
           fetch(`${process.env.REACT_APP_SERVER_URL}/api/chat?websiteRef=${websiteRef}&message=${userInput}&category=${selectedCategory}`),
           new Promise((_, reject) =>
             setTimeout(() => reject(new Error("Timeout")), 20000)
           )
         ]);


         const reader = response.body.getReader();
         reader.read().then(function pump({ done, value }) {
           if (done) {
             return;
           }


           test += decoder.decode(value);
           let message = urlify(test);
           message = message.replace(/^"|"$/g, '').replace(/\n/g, '<br>');
           const formattedResponse = '<p>' + message + '</p>';


           setMessages([...messages, { msg: formattedResponse, category: selectedCategory, fromUser: false }]);
           setLoading(false);


           return reader.read().then(pump);
         });
       } catch (error) {
         setMessages([...messages, { msg: "There is a temporary server downtime.", category: selectedCategory, fromUser: false }]);
         setLoading(false);
       }
     };


     fetchData();
     setUserInput('');
   }


   if (bottomRef.current) {
     bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
   }


 }, [messages]);


 const sendMessage = (event) => {
   event.preventDefault();


   if (loading || userInput === '') {
     return;
   }


   setMessages([...messages, { msg: userInput, category: selectedCategory, fromUser: true }]);
 };


 const handleKeyPress = (event) => {
   if (event.key === 'Enter' && event.shiftKey) {
     event.preventDefault();
     setUserInput((previous) => previous + "\n");
     adjustTextareaHeight();
   } else if (event.key === 'Enter') {
     event.preventDefault();
     sendMessage(event);
   } else if (event.key === 'Backspace') {
     adjustTextareaHeight();
   }
 };


 const [divHeight,setDivHeight] = useState(8)
 const adjustTextareaHeight = () => {
   const textarea = textareaRef.current;
   const maxHeight = 70;
   textarea.style.height = "auto";
  
   if (textarea.scrollHeight <= maxHeight) {
     textarea.style.height = `${textarea.scrollHeight}px`;
     textarea.style.overflowY = "hidden";
     setDivHeight(8)
   } else {
     textarea.style.height = `${maxHeight}px`;
     textarea.style.overflowY = "auto";
     setDivHeight(10)
   }

   // if (textarea.scrollHeight > textarea.clientHeight) {
   //   textarea.style.marginBottom = "5px";
   // } else {
   //   textarea.style.marginBottom = "0";
   // }
 };


 useEffect(() => {
   adjustTextareaHeight();
 }, [userInput]);



 return (
   <div className="flex flex-col h-full" style={{ backgroundColor: themes.mainBgColor, backgroundSize: "cover" }}>
     <div style={{ backgroundColor: themes.primaryColor }} className="w-full sticky flex justify-center flex-col chat-header">
       <div style={{ color: themes.logoColor }} className='font-bold p-5 text-center'>{botName} AI Chatbot
       {/* <p class="powered-by w-100 text-[12px] text-center font-normal">Powered by <a href="https://www.revize.com/" target="_blank">revize.AI</a>
        </p> */}
       </div>
     </div>
     
          <div className='h-full overscroll-y-auto'>

        {loading && (
            <div
              className='loading flex justify-center items-center w-full'
              style={{
                position: "absolute",
                height: "100%",
                pointerEvents: "none"
              }}
            >
            </div>
          )}
          
       {categories.length > 1 ? (
         <Select
           options={categories}
           onChange={handleTypeSelect}
           value={categories.filter(function (category) {
             return category.value === selectedCategory;
           })}
           placeholder="Select Category"
         />
       ) : ''}
       <div class="p-5 the-chat text-sm" ref={bottomRef} style={{ "whiteSpace": "pre-wrap", "overflowX": "hidden", "paddingBottom": "0" }}
       >
       <p class="px-5 pb-5 text-center text-[#737373]">Ask anything, and our AI chatbot will do it's best to answer your question.</p>
        <div class="rounded-lg mb-5 p-4 border-solid border flex gap-2 items-center text-[#737373]">
          <svg class="flex-none" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99994 12V7" stroke="#737373" stroke-width="1.7"></path>
            <circle cx="1.1" cy="1.1" r="1.1" transform="matrix(1 0 0 -1 6.89993 6.2002)" fill="#737373" stroke-width="0" stroke="none"></circle>
            <circle cx="8" cy="8" r="6.5" stroke="#737373" stroke-width="1.75"></circle>
          </svg>
          <p>This feature utilizes AI, and the information provided may not be fully accurate. We recommend verifying it independently.
          </p>
        </div>
        <ul className="message-block">
		 
         {messages && messages.map((message, idx) => (
           <div key={idx} className={`mt-4 ${message.fromUser ? "user-bubble" : "text-left bot-bubble"}`}>
             <div className="mt-4 p-4 rounded-xl"
               style={{
                 backgroundColor: message.fromUser ? themes.blackColor : '#f2f2f2',
                 color: message.fromUser ? themes.primaryFontColor : themes.secondryFontColor
               }}
             >
               <div className="break-words text-md" dangerouslySetInnerHTML={{ __html: message.msg }} />
				<div className="break-words first-visible" dangerouslySetInnerHTML={createMarkup()} />
             </div>
           </div>
		   
         ))}
       </ul>
       
       <p class="powered-by text-[13px] p-5 text-center text-[#737373]">Powered by <a href="https://www.revize.com/" class="underline" target="_blank">revize.AI,</a> the Government Website Experts <a href="https://www.revize.com/ai_terms/" class="underline" target="_blank">Terms of use</a> <a href="https://www.revize.com/ai_privacy/" class="underline" target="_blank">Privacy</a>
       </p>

     </div>

    </div>

     <div className={`py-3 shadow-xl sticky w-full justify-center items-center flex sticky bottom-0 chat-bar z-10`}>
 <form
   className='w-full px-3 flex flex-col justify-center'
   onSubmit={sendMessage}
   onKeyDown={handleKeyPress}
   style={{ alignItems: 'flex-end' }}
 >
   <div className="flex w-full relative">
   <img className='w-8 sparkels absolute top-4 left-2 h-4 w-4 z-10' src={sparkles} alt="sparkles" />
     <textarea
       ref={textareaRef}
       className='py-3 pl-10 pr-10 w-full rounded-xl border-0 outline-none'
       placeholder="Ask a question..."
       id="message"
       name="message"
       value={userInput}
       onChange={(e) => {
         setUserInput(e.target.value);
         adjustTextareaHeight();
       }}
       style={{ resize: "none", height: "auto", overflowY: "hidden", flexGrow: 1, minHeight : "46px", lineHeight : "1.2"}}
       rows={1}
       maxLength="1000"
     />
     <button
       type="submit"
       style={{
         opacity: loading ? 0.9 : 1,
         cursor: loading ? 'not-allowed' : 'pointer'
       }}
       className="absolute top-4 right-4 h-4 w-4"
       disabled={loading}
     >
       <img className='w-8' src={arrow} alt="arrow" />
     </button>
     
   </div>
    {/* <p class="w-100 m-0 text-[12px] text-center text-[#737373] pt-2 pl-2 self-center leading-3">
    Powered by <a href="https://www.revize.com/" class="underline" target="_blank">revize.AI</a>
    </p> */}
 </form>

</div> 

   </div>

 );
}


export default App;

